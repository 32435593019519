import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { Input, Button, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import * as actionCreators from '../actions';
import AgentDetails from './../components/AgentDetails';

const customerSupportPerm = 'SCSPRT.USERS.LIST';

export default function Home() {
  const dispatch = useDispatch();
  const { loadAgents, loadAgentDetails, setCardType } = bindActionCreators(actionCreators, dispatch);

  // Redux State
  const agents = useSelector(state => _.get(state, 'agents.list', []));
  const userData = useSelector(state => _.get(state, 'user.data', {}));
  const selectedAgent = useSelector(state => _.get(state, 'agents.selectedAgent', {}));
  const isFetchingAgents = useSelector(state => _.get(state, 'agents.fetchingAgents', false));
  const cardType = useSelector(state => _.get(state, 'agents.cardType', null));
  const market = useSelector(state => _.get(state, 'user.data.associateMarket.market', ''));

  let permissions = _.get(userData, 'permissions', []);
  let marketId = _.get(userData, 'associateMarket.market', false);
  let isCustomerSupport = permissions.includes(customerSupportPerm);

  // Local state
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (marketId) {
      loadAgents(marketId);
    }
  }, [marketId]);

  const agentCaption = (agent) => {
    let name = agent.name ? ` - ${agent.name}` : '';
    let email = agent.email ? ` (${agent.email})` : '';
    return `${agent.contactId}${name}${email}`
  };

  const filteredAgents = _.filter(agents, agent => _.toLower(agentCaption(agent)).includes(_.toLower(searchTerm)));

  const onSelectAgent = contactId => {
    setCardType('DETAILS');
    loadAgentDetails(contactId);
  };

  const onClose = () => {
    setCardType(null);
  };

  const onClickNewAgent = () => {
    setCardType('NEW');
  };

  const renderAppHeader = () => {
    return (
      <div className="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        />
        <div className="bread-crum-seperator"></div>
        <div className="title">SC1 Support</div>
      </div>
    );
  }; 

  const renderAgentsList = () => {
    if (!_.isEmpty(agents)) {
      return (
        <ul className="agents-list">
          {filteredAgents.map(agent => (
            <li key={agent.contactId} className="agent-item" onClick={() => onSelectAgent(agent.contactId)}>
              {agentCaption(agent)}
            </li>
          ))}
        </ul>
      );
    } else if (isFetchingAgents) {
      return (
        <div className="empty-agents-list">
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </div>
      );
    } else {
      return <div className="empty-agents-list">No agents yet</div>;
    }
  };

  return (
    <React.Fragment>
      {renderAppHeader()}
      {
        isCustomerSupport && <div className="agents-container">
          <div className='market'>Market: {market}</div>
          <div className="action-bar">
            <Input
              placeholder="Search agents..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="search-bar"
            />
            <Button type="primary" className="add-agent-button" onClick={onClickNewAgent}>
              Add SC
            </Button>
          </div>
          {renderAgentsList()}
        </div>
      }
      {!_.isNull(cardType) && <AgentDetails cardType={cardType} contact={selectedAgent} onClose={onClose} />}
    </React.Fragment>
  );
}
