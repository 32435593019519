const agents = {
  list: [],
  fetchingAgents: false,
  cardType: null, // cardType = NEW | UPDATE | DETAILS
  selectedAgent: {},
  fetchingAgentDetails: false,
  isCreatingAgent: false,
  isDeletingAgent: false
};

export default agents;
