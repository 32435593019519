import { takeLatest } from 'redux-saga/effects';
import { AGENTS, AGENT_DETAILS, CREATE_AGENT, DELETE_AGENT } from 'actions/actionTypes';
import { loadAgentsAsync, loadAgentDetailsAsync, saveAgentAsync, deleteAgentAsync } from './AgentsSaga';

const agents = [takeLatest(AGENTS.REQUEST, loadAgentsAsync)];
const agentDetails = [takeLatest(AGENT_DETAILS.REQUEST, loadAgentDetailsAsync)];
const saveAgent = [takeLatest(CREATE_AGENT.REQUEST, saveAgentAsync)];
const deleteAgent = [takeLatest(DELETE_AGENT.REQUEST, deleteAgentAsync)];

export { agents, agentDetails, saveAgent, deleteAgent };
