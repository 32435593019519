import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUser } from 'selectors/userSelector';
import { processToHash } from '../utils/Util';

const customerSupportRole = 1730;

const IntercomProvider = ({}) => {
  const { contactId, email, permissions, name } = useSelector(selectUser);
  const currentUnixTimestamp = Math.floor(Date.now() / 1000);
  const showIntercomMessenger = (permissions || []).includes('SCSPRT.INTERCOM.VIEW');

  useEffect(() => {
    if (!showIntercomMessenger) {
      return () => {};
    }

    const script = document.createElement('script');
    script.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Intercom) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          user_id: processToHash(email),
          name,
          email
        });

        if ((permissions || []).includes('SCSPRT.INTERCOM.VIEW')) {
          // Always show the messenger and prevent closing for sales consultants
          window.Intercom('show'); // Pops out the messenger
          // window.Intercom('onHide', () => window.Intercom('show')); // Reopen if closed
          // window.Intercom('onHide', () => window.Intercom('hide')); // hide the chatbot and display the intercom chatbot icon only
        } else {
          window.Intercom('update', {
            hide_default_launcher: true
          });
        }
      }
    };

    return () => {
      document.body.removeChild(script);
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [contactId, email, permissions, showIntercomMessenger]);

  return null;
};

IntercomProvider.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired
  }).isRequired
};

export default IntercomProvider;
