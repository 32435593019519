import { action } from 'reduxHelpers';
import { USER, AGENTS, AGENT_DETAILS, CREATE_AGENT, CARD_TYPE, DELETE_AGENT } from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const loadAgents = marketId => action(AGENTS.REQUEST, { marketId });
const loadAgentDetails = contactId => action(AGENT_DETAILS.REQUEST, { contactId });
const saveAgent = data => action(CREATE_AGENT.REQUEST, data);
const setCardType = type => action(CARD_TYPE.REQUEST, { payload: { type } });
const deleteAgent = contactId => action(DELETE_AGENT.REQUEST, { contactId });

export { loadUserDetails, loadAgents, loadAgentDetails, saveAgent, setCardType, deleteAgent };
