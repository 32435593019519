const crypto = require('crypto');

const processToHash = input => {
  // Trim whitespace
  const trimmed = input.trim();

  // Convert to lowercase
  const lowercased = trimmed.toLowerCase();

  // Create MD5 hash
  return crypto
    .createHash('md5')
    .update(lowercased)
    .digest('hex');
};

module.exports = {
  processToHash
};
