import initialState from 'store/initialState';

import { USER } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const getUserObj = (obj) => {
  obj.permissions = obj.applications;
  return obj;
};

const user = (state = initialState.user, action) => {
  switch (action.type) {
    case USER.REQUEST:
      return { ...state, fetching: true, error: null };
    case USER.SUCCESS:
      return { ...state, data: getUserObj({ ...action.payload }), fetching: false };
    case USER.FAILURE:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
};

createReducer(USER, initialState.user);

export default user;
