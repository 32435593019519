import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const AGENTS = createRequestTypes('AGENTS');
const AGENT_DETAILS = createRequestTypes('AGENT_DETAILS');
const CREATE_AGENT = createRequestTypes('CREATE_AGENT');
const CARD_TYPE = createRequestTypes('CARD_TYPE');
const DELETE_AGENT = createRequestTypes('DELETE_AGENT');

export { USER, AGENTS, AGENT_DETAILS, CREATE_AGENT, CARD_TYPE, DELETE_AGENT };
